import Framework from "../../framework/Framework";
import store from "../../store/store";
import UserAction from "../../store/action/UserAction";

/**
 *
 */
class ApiUser extends Framework.CoreApi {
  static className = "ApiUser";

  path = "/users";

  /**
   *
   * @param username
   * @param password
   * @returns {*}
   */
  login(username, password) {
    const url = this.getBaseUrl("/auth/login");
    const data = { username, password };
    return this.post(url, data);
  }

  logOut() {
    const url = this.getBaseUrl("/auth/logout");
    return this.post(url);
  }

  getAllUser(param) {
    const url = this.getBaseUrl(this.path);
    return this.get(url, param, { getMeta: true });
  }

  addUser(data) {
    const url = this.getBaseUrl("/auth/register");
    return this.post(url, data);
  }

  changeInfo(firstName, lastName) {
    const url = this.getBaseUrl(this.path + "/me");
    const data = { firstName, lastName };
    return this.put(url, data);
  }

  changePassword(params) {
    const url = this.getBaseUrl(this.path + "/change-password");
    return this.post(url, params);
  }

  changePasswordUser(id, newPassword) {
    const url = this.getBaseUrl(this.path + `/${id}/set-password`);
    return this.post(url, { newPassword });
  }

  changeInfoUser(id, data) {
    const url = this.getBaseUrl(this.path + `/${id}`);
    return this.put(url, data);
  }

  deleteUser(data) {
    const url = this.getBaseUrl(this.path + "/bulk-delete");
    return this.multiDelete(url, data);
  }

  getMe(options = {}) {
    const url = this.getBaseUrl(this.path + "/me");
    return this.get(url, {}, options);
  }
}

export default Framework.ApiFactory.get(ApiUser);
