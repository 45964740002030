import Framework from "../../framework/Framework";

/**
 *
 */
class ApiAlert extends Framework.CoreApi {
  static className = "ApiAlert";

  path = "/warn";

  getAllItems(param) {
    const url = this.getBaseUrl(this.path);
    return this.get(url, param, { getMeta: true });
  }

  getFilterDistinctData(param) {
    const url = this.getBaseUrl(`${this.path}/filter-options`);
    return this.get(url, param);
  }

  changeProjectActionType(body) {
    const url = this.getBaseUrl(`${this.path}/${body.id}`);
    return this.put(url, body.actionStatus);
  }
}

export default Framework.ApiFactory.get(ApiAlert);
