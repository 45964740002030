/**
 * Auth routes
 */
import Auth from "../view/screens/0-Auth/Auth";

/**
 * Private routes
 */
import UserManagementPage from "../view/screens/1-User/UserManagementPage";
// import SiteProjectManagementPage from "../view/screens/2-Project/SiteProjectManagementPage";
// import ReportManagementPage from "../view/screens/3-Report/ReportManagementPage.js";
import AlertManagementPage from "../view/screens/4-Alert/AlertManagementPage";
import Config from "../config";
import ReportManagementPage from "../view/screens/3-Report/ReportManagementPage";

/**
 *
 * @type {(*|{path: string, children: [{path: string, component: *, name: string, isPrivacy: boolean}], name: string})[]}
 */
const allPrivateScreens = [
  {
    path: Config.PATHNAME.HOME,
    name: "User Management",
    component: UserManagementPage,
    roleRequired: ["superadmin", "admin"],
  },
  // {
  //   path: "/project",
  //   name: "Site and Project Management",
  //   component: SiteProjectManagementPage,
  //   roleRequired: ["superadmin", "admin", "viewer"],
  // },
  // {
  //   path: "/report",
  //   name: "Report",
  //   component: ReportManagementPage,
  //   roleRequired: ["superadmin", "admin", "viewer"],
  // },
  {
    path: "/alert",
    name: "Alert",
    component: AlertManagementPage,
    roleRequired: ["superadmin", "admin", "viewer"],
  },
];

const dashboardScreens = [];
const privacyScreens = [];
allPrivateScreens.forEach((masterScreen) => {
  if (masterScreen.children) {
    masterScreen.children.forEach((screen, index) => {
      if (screen.isPrivacy) {
        screen.path = masterScreen.path + screen.path;
        privacyScreens.push(screen);
        masterScreen.children.splice(index, 1);
      }
    });
    dashboardScreens.push(masterScreen);
  } else {
    if (masterScreen.isPrivacy) {
      privacyScreens.push(masterScreen);
    } else {
      dashboardScreens.push(masterScreen);
    }
  }
});

/**
 * Public Routes
 */
const authRoutes = [
  {
    path: Config.PATHNAME.AUTH,
    component: Auth,
    name: "AuthRoute",
  },
];

const publicRoutes = [];

/**
 * Private Routes
 */
// Dashboard routes will appear on menu sidebar
export const dashboardRoutes = dashboardScreens;

// Privacy routes will not appear on menu sidebar
const privacyRoutes = privacyScreens;
/**
 *
 * Export
 */

const privateRoutes = [...dashboardRoutes, ...privacyRoutes];
export default {
  authRoutes,
  publicRoutes,
  privateRoutes,
};
