import Framework          from "../framework/Framework";
import store, {persistor} from "../store/store";
import ApiUser            from "../data/api/ApiUser";
import i18next            from "i18next";

/**
 *
 */
class UserService extends Framework.CoreService {
    static className = "UserService";

    getDataOnline = ApiUser;

    /**
     *
     * @param username
     * @param password
     * @returns {Promise<unknown>}
     */
    loginBase(username, password) {
        return ApiUser.login(username, password);
    }

    /**
     *
     */
    isLogin() {
        return !!this.getAuthToken();
    }

    getAuthToken() {
        return store.getState().core.user?.accessToken ?? "";
    }

    logOut() {
        ApiUser.logOut();
        persistor
            .purge()
            .then(() => {
                window.location.reload();
            })
            .catch(() => {
                // eslint-disable-next-line no-alert
                window.alert(
                    i18next.t("Browser error. Clear Browser Cookies and try again")
                );
            });
    }

    getListRoleCode() {
        const role = [];
        role.push(store.getState().core.user.role);
        return role;
    }

}

export default Framework.ServiceFactory.get(UserService);
