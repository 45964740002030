const isDataEmpty = (data) => data || <p>-</p>;
const compare = (a, b, key) => {
  if ([a[key], b[key]].includes(undefined)) return a[key] ? 1 : -1;
  return a[key] ? a[key].localeCompare(b[key]) : 0;
};
const ReportManagementTableConfig = (self) => [
  {
    title: "No.",
    width: 50,
    dataIndex: "id",
    key: "No",
    align: "center",
    render: (value, record, index) => <span>{index + 1}</span>,
  },
  {
    title: "Site name ",
    width: 100,
    dataIndex: ["proj", "site", "name"],
    key: "sitename",
    align: "center",
    sorter: (a, b) => a.proj.site.name.localeCompare(b.proj.site.name),
    render: (_, record) => (
      <div>
        <p className="mb-0">{record.proj.site.name}</p>
        <p className="mb-0">{`(${record.proj.site.entityId})`}</p>
      </div>
    ),
  },
  {
    title: "Project name ",
    width: 100,
    dataIndex: ["proj", "name"],
    key: "project_name",
    align: "center",
    sorter: (a, b) => a.proj.name.localeCompare(b.proj.name),
    render: (value, record) =>
      record.proj ? (
        <div>
          <p className="mb-0">{record.proj.name}</p>
          <p className="mb-0">({record.proj.entityId})</p>
        </div>
      ) : (
        <p>-</p>
      ),
  },
  {
    title: "Revenue",
    width: 400,
    align: "center",
    children: [
      {
        title: "Quantity  ",
        width: 100,
        dataIndex: ["curQuantity", "quantity"],
        align: "center",
        key: 1,
        sorter: compare,
        render: isDataEmpty,
      },
      {
        title: "%",
        width: 100,
        dataIndex: ["curQuantity", "cur"],
        align: "center",
        key: 2,
        sorter: compare,
        render: isDataEmpty,
      },
      {
        title: "Factory",
        width: 100,
        dataIndex: ["curQuantity", "factory"],
        align: "center",
        key: 3,
        sorter: compare,
        render: isDataEmpty,
      },
      {
        title: "EVN ",
        width: 100,
        dataIndex: ["curQuantity", "evn"],
        align: "center",
        key: 4,
        sorter: compare,
        render: isDataEmpty,
      },
      {
        title: "Total revenue",
        width: 100,
        dataIndex: ["curQuantity", "total"],
        align: "center",
        key: 5,
        sorter: compare,
        render: isDataEmpty,
      },
    ],
  },
  {
    title: "Total revenue",
    width: 400,
    align: "center",
    children: [
      {
        title: "Quantity  ",
        width: 100,
        dataIndex: ["preQuantity", "quantity"],
        align: "center",
        sorter: compare,
        render: isDataEmpty,
      },
      {
        title: "%",
        width: 100,
        dataIndex: ["preQuantity", "cur"],
        align: "center",
        sorter: compare,
        render: isDataEmpty,
      },
      {
        title: "Factory",
        width: 100,
        dataIndex: ["preQuantity", "factory"],
        align: "center",
        sorter: compare,
        render: isDataEmpty,
      },
      {
        title: "EVN ",
        width: 100,
        dataIndex: ["preQuantity", "evn"],
        align: "center",
        sorter: compare,
        render: isDataEmpty,
      },
      {
        title: "Total revenue",
        width: 100,
        dataIndex: ["preQuantity", "total"],
        align: "center",
        sorter: compare,
        render: isDataEmpty,
      },
    ],
  },
];

export { ReportManagementTableConfig };
