import React from "react";
import Framework from "../../../framework/Framework";
import { Card, Image } from "antd";
import Login from "./Login";
import autoBind from "../../../utils/autoBind/AutoBind";
import Logo from "../../../assets/LOGO.png";

/**
 *
 */
class Auth extends Framework.CoreComponent {
  static className = "AuthComponent";

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      companyName: "",
    };
    autoBind(this);
  }

  authPages = [{ hash: "#login", component: <Login /> }];

  componentDidMount() {
    this.handleLoadData();
  }

  handleLoadData() {}

  template() {
    const { hash } = this.props.location;
    const currentPage = this.authPages.find((page) => page.hash === hash);
    return (
      <div className="auth-page-all justify-content-center align-items-center">
        <div className="auth-page d-flex flex-column justify-content-center align-items-center">
          <Image
            width={394}
            src={Logo}
            preview={false}
            className="logo-text-mb mb-2 d-flex"
          />
          <Card className="auth-card">
            {currentPage ? currentPage.component : <Login />}
          </Card>
        </div>
      </div>
    );
  }
}

/**
 *
 */
class AuthContainer extends Framework.CoreContainer {}

export default Framework.ContainerFactory.get(AuthContainer).withRouter(
  Framework.ComponentFactory.get(Auth)
);
