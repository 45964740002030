import React, { useState, useEffect, useRef } from "react";
import propTypes from "prop-types";

function Timer({ initialMinute, timeoutFunction }) {
  const [currentTime, setCurrentTime] = useState({
    total: initialMinute * 60,
    currentMinute: initialMinute,
    currentSecond: 60,
  });
  const savedTime = useRef(currentTime);
  const handleSync = () => {
    savedTime.current.total -= 1;
    if (savedTime.current.total === 0) {
      timeoutFunction();
      savedTime.current.total = initialMinute * 60;
    }
    setCurrentTime({
      ...currentTime,
      currentSecond: savedTime.current.total % 60,
      currentMinute: Math.floor(savedTime.current.total / 60),
    });
  };

  useEffect(() => {
    setCurrentTime(savedTime.current);
    setInterval(handleSync, 1000);
  }, []);

  return (
    <h4 className="mb-0">
      {currentTime.currentMinute < 10
        ? `${"0" + currentTime.currentMinute}`
        : currentTime.currentMinute}
      :
      {currentTime.currentSecond === 60
        ? "00"
        : currentTime.currentSecond < 10
        ? `${"0" + currentTime.currentSecond}`
        : currentTime.currentSecond}
    </h4>
  );
}

export default Timer;

Timer.propTypes = {
  initialMinute: propTypes.number.isRequired,
  timeoutFunction: propTypes.func.isRequired,
};
