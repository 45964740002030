import axios from "axios";
import Config from "../../config";
import { Modal, notification } from "antd";
import _ from "lodash";
import store, { persistor } from "../../store/store";
import UserAction from "../../store/action/UserAction";
import ListError from "../ListError";
import i18next from "i18next";
import UserService from "../../service/UserService";

/**
 *
 */
export default class CoreApi {
  somethingsWrong = {
    error_code: "ERROR???",
    error_message: i18next.t(
      "An unknown error. Please contact technical department"
    ),
  };

  _defaultHeaders = {
    "Content-Type": "application/json",
  };

  /**
   *
   * @param dataError
   */
  displayError(dataError) {
    const errMessage = dataError.message;
    try {
      notification.error({
        message: i18next.t("Not connect to server"),
        description: errMessage,
      });
    } catch (e) {
      notification.error({
        message: i18next.t(
          "An unknown error. Please contact technical department"
        ),
        description: _.toString(e),
      });
    }
  }

  /**
   * Get 1-Auth token
   * @returns {*}
   */
  getToken() {
    return store.getState().core.user?.accessToken ?? "";
  }

  logOut() {
    persistor
      .purge()
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        window.alert(
          i18next.t("Browser error. Clear Browser Cookies and try again")
        );
      });
  }

  /**
   *
   * @param options
   * @returns {{Authorization: string, "Content-Type": string}}
   */
  getHeadersRequest(options = {}) {
    return {
      ...this._defaultHeaders,
      Authorization: `Bearer ${this.getToken()}`,
      ...(options.headers ?? {}),
    };
  }

  /**
   * Get Base URL
   * @returns {string}
   */
  getBaseUrl(path) {
    if (path) {
      return Config.NETWORK_CONFIG.API_BASE_URL + path;
    }
    return Config.NETWORK_CONFIG.API_BASE_URL;
  }

  handleRefreshToken() {
    return new Promise((resolve, reject) => {
      axios
        .post(`${this.getBaseUrl()}/auth/refresh-token`, {
          refreshToken: store.getState().core.user?.refreshToken,
        })
        .then((result) => {
          if (result.data.errorCode === "JWT012S") {
            Modal.confirm({
              content: i18next.t("Login session expired Please log in again!"),
              onOk: () => {
                this.logOut();
              },
            });
          }
          if (result.data.success) {
            store.dispatch(UserAction.userLoginResult(result.data.data));
            resolve(true);
          }
        })
        .catch((error) => {
          this.displayError(error);
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(false);
        });
    });
  }

  // eslint-disable-next-line default-param-last
  async handleResult(resolve, reject, result, options = {}) {
    if (result.config.responseType === "blob") {
      if (result.status === 200) {
        resolve(result);
      } else {
        reject(result.status);
      }
    } else {
      let { data } = result;
      if (data.success) {
        if (options.getHeaders) {
          data = {
            headers: result.headers,
            data: result.data,
          };
        }
        resolve(data);
      } else {
        switch (data.errorCode) {
          case "JWT011G":
          case "JWT012S":
            Modal.confirm({
              title: i18next.t("Login session expired"),
              content: i18next.t("Please log in again!"),
              okText: "Confirm",
                  onOk: () => {
                    UserService.getDataOnline
                        .logOut()
                        .then(() => {
                          UserService.logOut();
                        })
                        .finally(() => {
                          UserService.logOut();
                        });
                  },
              okCancel: false,
              keyboard: false,
            });
            break;
          default:
            if (!options.noDisplayError) {
              ListError.render(data.message);
            }
        }
        reject(data.message);
      }
    }
  }

  handleError(reject, error, options) {
    const dataError = error?.response?.data ?? this.somethingsWrong;
    if (!options.noDisplayError) {
      this.displayError(dataError);
    }
    reject(dataError);
  }

  /**
   * Method GET
   * @param url
   * @param params
   * @param options
   * @returns {Promise<unknown>}
   */
  get(url, params = {}, options = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: params,
          headers: this.getHeadersRequest(options),
          timeout: Config.NETWORK_CONFIG.TIMEOUT,
        })
        .then((result) =>
          this.handleResult(
            resolve,
            reject,
            result,
            options,
            this.get.bind(this, url, params, options)
          )
        )
        .catch((error) => this.handleError(reject, error, options));
    });
  }

  /**
   * Method POST
   * @param url
   * @param data
   * @param options
   * @returns {Promise<unknown>}
   */
  post(url, data = {}, options = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, data, {
          headers: this.getHeadersRequest(options),
          timeout: Config.NETWORK_CONFIG.TIMEOUT,
        })
        .then((result) =>
          this.handleResult(
            resolve,
            reject,
            result,
            options,
            this.post.bind(this, url, data, options)
          )
        )
        .catch((error) => this.handleError(reject, error, options));
    });
  }

  /**
   * Method PUT
   * @param url
   * @param data
   * @param options
   * @returns {Promise<unknown>}
   */
  put(url, data = {}, options = {}) {
    return new Promise((resolve, reject) => {
      axios
        .put(url, data, {
          headers: this.getHeadersRequest(options),
          timeout: Config.NETWORK_CONFIG.TIMEOUT,
        })
        .then((result) =>
          this.handleResult(
            resolve,
            reject,
            result,
            options,
            this.put.bind(this, url, data, options)
          )
        )
        .catch((error) => this.handleError(reject, error, options));
    });
  }

  /**
   * Method PATCH
   * @param url
   * @param data
   * @param options
   * @returns {Promise<unknown>}
   */
  patch(url, data = {}, options = {}) {
    return new Promise((resolve, reject) => {
      axios
        .patch(url, data, {
          headers: this.getHeadersRequest(options),
          timeout: Config.NETWORK_CONFIG.TIMEOUT,
        })
        .then((result) =>
          this.handleResult(
            resolve,
            reject,
            result,
            options,
            this.patch.bind(this, url, data, options)
          )
        )
        .catch((error) => this.handleError(reject, error, options));
    });
  }

  /**
   * Method DELETE
   * @param url
   * @param params
   * @param options
   * @returns {Promise<unknown>}
   */
  delete(url, params = {}, options = {}) {
    return new Promise((resolve, reject) => {
      axios
        .delete(url, {
          headers: this.getHeadersRequest(options),
          timeout: Config.NETWORK_CONFIG.TIMEOUT,
          params: params,
        })
        .then((result) =>
          this.handleResult(
            resolve,
            reject,
            result,
            options,
            this.delete.bind(this, url, params, options)
          )
        )
        .catch((error) => this.handleError(reject, error, options));
    });
  }

  multiDelete(url, data = {}, options = {}) {
    return new Promise((resolve, reject) => {
      axios
        .delete(url, {
          headers: this.getHeadersRequest(options),
          timeout: Config.NETWORK_CONFIG.TIMEOUT,
          data: data,
        })
        .then((result) =>
          this.handleResult(
            resolve,
            reject,
            result,
            options,
            this.multiDelete.bind(this, url, data, options)
          )
        )
        .catch((error) => this.handleError(reject, error, options));
    });
  }

  download(url, params = {}, options = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          responseType: "blob",
          headers: this.getHeadersRequest(options),
          timeout: Config.NETWORK_CONFIG.TIMEOUT,
          params: params,
        })
        .then((result) => this.handleResult(resolve, reject, result, options))
        .catch((error) => this.handleError(reject, error, options));
    });
  }

  downloadGraph(url, params = {}, options = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: params,
          responseType: "blob",
          headers: this.getHeadersRequest({
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.getToken()}`,
            ...(options.headers ?? {}),
          }),
          timeout: Config.NETWORK_CONFIG.TIMEOUT,
        })
        .then((result) => this.handleResult(resolve, reject, result, options))
        .catch((error) => this.handleError(reject, error, options));
    });
  }
}
