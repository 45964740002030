import UserConstant from "../constant/UserConstant";

const initialState = {};

class UserReducer {
    static className = "UserReducer";

    static reducerName = "user";

    // eslint-disable-next-line default-param-last
    static reducer(state = initialState, action) {
        switch (action.type) {
            case UserConstant.USER_LOGIN_RESULT:
                return {
                    ...state,
                    ...action.user
                };
            case UserConstant.USER_LOGIN_ERROR:
                return {
                    ...state,
                    error: action.error
                };
            case UserConstant.USER_LOGOUT:
                return initialState;
            default:
                return state;
        }
    }
}


export default UserReducer;
