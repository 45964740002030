import React, {Component} from "react";
import propTypes          from "prop-types";

// eslint-disable-next-line react/prefer-stateless-function
class Content extends Component {
    render() {
        return (
            <div className="content">
                {this.props.children}
            </div>
        );
    }
}

Content.propTypes = {
    children: propTypes.oneOfType([
        propTypes.arrayOf(propTypes.node),
        propTypes.node
    ]).isRequired
};

export default Content;
