import React from "react";
import Framework from "../../../framework/Framework";
import { Button, Input, Modal, Table, Tooltip } from "antd";
import autoBind from "../../../utils/autoBind/AutoBind";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { UserManagementTableConfig } from "./UserManagementTableConfig";
import ModalUserProfile from "./ModalUserProfile";
import UserService from "../../../service/UserService";
import Notify from "../../../utils/Notify/Notify";
import store from "../../../store/store";

/**
 *
 */
class UserManagementPage extends Framework.CoreComponent {
  static className = "UserComponent";

  constructor(props) {
    super(props);
    this.colTable = UserManagementTableConfig(this);
    this.state = {
      search: "",
      isLoading: false,
      typeModal: "",
      listUser: [],
      dataSelected: [],
      dataRowsSelected: [],
      currentPage: 1,
      pageSize: 10,
      total: null,
    };
    autoBind(this);
  }

  // Life cycle
  componentDidMount() {
    this.handleLoadData(false);
  }

  // Functions
  handleLoadData(isSearch) {
    this.setState({ isLoading: true });
    UserService.getDataOnline
      .getAllUser({ search: isSearch ? this.state.search : undefined })
      .then((res) => {
        this.setState({
          listUser: res.data,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  handleChange(e) {
    if (e.target.value === "" && e.target.name === "search") {
      this.setState(
        {
          search: "",
        },
        () => {
          this.handleLoadData(true);
        }
      );
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleChangeTable() {
    this.handleLoadData(true);
  }

  handleSearch() {
    this.handleLoadData(true);
  }

  handleOpenModal(type, data) {
    if (type === "delete") {
      Modal.confirm({
        title: "Confirm delete account",
        okText: "Confirm",
        cancelText: "Cancel",
        icon: <ExclamationCircleOutlined className="gold-6" />,
        onOk: () => this.handleDeleteUser(data, "single"),
      });
    } else {
      this.setState({
        dataSelected: data,
        typeModal: type,
      });
    }
  }

  handleCloseModal() {
    this.setState({ typeModal: "" });
  }

  handleDeleteUser(data, type) {
    return new Promise((resolve) => {
      this.setState({ isLoading: true });
      // dataSelected convert to ids array
      const ids = type === "single" ? [data?.id] : data;
      UserService.getDataOnline
        .deleteUser({ ids })
        .then(() => {
          Notify.success("Deleted successfully.");
          this.handleLoadData(false);
          this.setState({ isLoading: false });
          resolve();
        })
        .catch(() => {
          this.setState({ isLoading: false });
          Notify.success("Deleted unsuccessfully.");
          resolve();
        });
    });
  }

  handleDeleteMultiUser() {
    Modal.confirm({
      title: "Confirm delete account",
      okText: "Confirm",
      cancelText: "Cancel",
      icon: <ExclamationCircleOutlined className="color-danger" />,
      onOk: () => this.handleDeleteUser(this.state.dataRowsSelected, "multi"),
    });
  }

  // Render
  template() {
    return (
      <div className="user-page table-container p-4 m-3">
        <ModalUserProfile
          dataSource={this.state.dataSelected}
          type={this.state.typeModal}
          onClose={this.handleCloseModal}
          onLoad={this.handleLoadData}
          pageSize={this.state.pageSize}
        />
        <div className="d-flex justify-content-between mb-3">
          <Tooltip title="Name, Username" placement="top">
            <Input.Search
              style={{ width: 300 }}
              name="search"
              value={this.state.search}
              onChange={this.handleChange}
              onSearch={this.handleSearch}
              placeholder="Search"
              allowClear
            />
          </Tooltip>
          <div className="d-flex justify-space-between">
            <Button
              disabled={this.state.dataRowsSelected.length === 0}
              className="btn-user mr-3"
              icon={<DeleteOutlined />}
              onClick={this.handleDeleteMultiUser}
              loading={this.state.isLoading}
              type="text"
              size="middle"
            >
              Delete
            </Button>
            <Button
              className="btn-user"
              icon={<PlusOutlined />}
              onClick={this.handleOpenModal.bind(this, "add")}
              loading={this.state.isLoading}
              type="text"
              size="middle"
            >
              Add
            </Button>
          </div>
        </div>
        <Table
          className="table"
          size="small"
          columns={this.colTable}
          dataSource={this.state.listUser}
          rowKey="id"
          bordered
          rowSelection={{
            type: "checkbox",
            onChange: (selectedRowKeys) => {
              this.setState({ dataRowsSelected: selectedRowKeys });
            },
            onSelect: (record) => {
              this.setState({ dataRowsSelected: record.id });
            },
            getCheckboxProps: (record) => ({
              disabled:
                record.role.toLowerCase() === "superadmin"
                || (store.getState().core.user.data.role.toLowerCase()
                  === "admin"
                  && record.role.toLowerCase() === "admin")
                || store.getState().core.user.data.role.toLowerCase() === "viewer",
            }),
          }}
          pagination={false}
          onChange={this.handleChangeTable}
          loading={this.state.isLoading}
          scrollToFirstRowOnChange
          scroll={{ y: "75vh" }}
          locale={{
            triggerDesc: "Sort Z to A",
            triggerAsc: "Sort A to Z",
            cancelSort: "Back to initial order",
          }}
        />
      </div>
    );
  }
}

/**
 *
 */
class UserContainer extends Framework.CoreContainer {
  static className = "UserContainer";
}

export default Framework.ContainerFactory.get(UserContainer).withRouter(
  Framework.ComponentFactory.get(UserManagementPage)
);
