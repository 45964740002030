import React     from "react";
import Framework from "../../../framework/Framework";

import {Progress} from "antd";

import classes from "classnames";

/**
 *
 */
class TopProgress extends Framework.CoreComponent {
    static className = "TopProgressComponent";

    template() {

        const {progress, visibility} = this.props.topProgress;

        return (
            <div className={classes(
                "top-progressbar",
                visibility ? "show" : ""
            )}>
                <Progress percent={progress} status="active" showInfo={false}/>
            </div>
        );
    }
}

class TopProgressContainer extends Framework.CoreContainer {
    static mapState(state) {
        return {
            topProgress: state.core.topProgress
        };
    }
}

export default Framework.ContainerFactory.get(TopProgressContainer).withRouter(
    Framework.ComponentFactory.get(TopProgress)
);
