import Framework from "../framework/Framework";
import store, { persistor } from "../store/store";
import ApiReport from "../data/api/ApiReport";
import i18next from "i18next";

/**
 *
 */
class ReportService extends Framework.CoreService {
  static className = "ReportService";

  getDataOnline = ApiReport;
}

export default Framework.ServiceFactory.get(ReportService);
