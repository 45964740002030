import React from "react";
import { Input, Modal } from "antd";
import propTypes from "prop-types";
import UserService from "../../../service/UserService";
import Notify from "../../../utils/Notify/Notify";

const ModalChangePassword = React.memo(({ isOpen, onClose }) => {
  // Ref
  const listField = React.useRef([
    {
      label: "Current password",
      key: "currentPassword",
    },
    {
      label: "New password",
      key: "password",
    },
    {
      label: "Confirm password",
      key: "rePassword",
    },
  ]).current;

  // State
  const [data, setData] = React.useState({
    currentPassword: "",
    password: "",
    rePassword: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);

  // Function
  const handleClose = () => {
    setData({
      password: "",
      rePassword: "",
    });
    onClose();
  };

  const handleOk = () => {
    const { currentPassword, password, rePassword } = data;

    if (!currentPassword || !password || !rePassword) {
      Notify.error("Please enter the data in all cells.");
    }

    if (password.length < 5) {
      Notify.error("Password length must be between 5-30 characters.");
      return;
    }
    if (rePassword.length < 5) {
      Notify.error("Password length must be between 5-30 characters.");
      return;
    }
    if (password !== rePassword) {
      Notify.error("Confirm password does not match the new password.");
      return;
    }
    setIsLoading(true);
    const params = {
      currentPassword: currentPassword,
      newPassword: password,
    };
    UserService.getDataOnline
      .changePassword(params)
      .then(() => {
        Notify.success("Password changed successfully");
        handleClose();
        setTimeout(() => {
          UserService.logOut();
        }, 1500);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value.replace(/\s/g, "") });
  };

  // Render
  return (
    <Modal
      visible={isOpen}
      title={<span className="pl-3">Change password</span>}
      onCancel={handleClose}
      onOk={handleOk}
      closable={false}
      cancelText="Cancel"
      okText="Change"
      confirmLoading={isLoading}
      width="400px"
      okButtonProps={{
        disabled: !data.password || !data.rePassword,
      }}
    >
      {listField.map(({ key, label }, index) => (
        <div key={index} className="mb-3 px-3">
          <div className="row-vertical-center">
            <h4 className="required" style={{ fontWeight: "middle" }}>
              {label}
            </h4>
          </div>
          <Input.Password
            maxLength={30}
            placeholder={label}
            value={data[key]}
            onChange={handleChange}
            name={key}
          />
        </div>
      ))}
    </Modal>
  );
});

ModalChangePassword.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default ModalChangePassword;
