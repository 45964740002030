import { notification } from "antd";

/**
 *
 */

class ListError {
  render(message) {
    notification.error({
      message: message,
    });
  }
}

export default new ListError();
