import React from "react";
import { Button, Result } from "antd";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { Translation } from "react-i18next";
import UserService from "../service/UserService";
import RouteList from "./RouteList";
import LandingLayout from "../view/component/Layout/LandingLayout";
import DashboardLayout from "../view/component/Layout/DashboardLayout";
import Config from "../config";
import store from "../store/store";
import _ from "lodash";
import i18next from "i18next";
import propTypes from "prop-types";

/**
 *
 * @param pathname
 * @returns {boolean}
 */
const needRedirect = (pathname) => {
  if (UserService.isLogin()) {
    return false;
  }
  return pathname !== Config.PATHNAME.AUTH;
};

/**
 *
 * @param Component
 * @param path
 * @param disabled
 * @returns {*}
 * @constructor
 */
// eslint-disable-next-line react/prop-types
function PrivateRoute({ component: Component, path, role }) {
  const roleCode = useSelector((state) => state.core.user?.data?.role);
  const isDisabled = () => {
    if (_.isArray(role)) {
      // eslint-disable-next-line react/prop-types
      return !role.some((d) => d === roleCode);
    }
    return false;
  };
  return (
    <Route
      path={path}
      render={(props) =>
        !needRedirect(props.location.pathname) && !isDisabled() ? (
          <DashboardLayout>
            <Component {...props} />
          </DashboardLayout>
        ) : (
          <Redirect to={{ pathname: Config.PATHNAME.AUTH }} />
        )
      }
    />
  );
}

PrivateRoute.defaultProps = {
  location: {
    pathname: "",
  },
};

PrivateRoute.propTypes = {
  location: propTypes.shape({
    pathname: propTypes.string.isRequired,
  }),
};

function PublicRoute({ component: Component, path, exact }) {
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => (
        <LandingLayout>
          <Component {...props} />
        </LandingLayout>
      )}
    />
  );
}

PublicRoute.defaultProps = {
  exact: "",
};

PublicRoute.propTypes = {
  component: propTypes.oneOfType([propTypes.object, propTypes.func]).isRequired,
  path: propTypes.string.isRequired,
  exact: propTypes.string,
};

function AuthRoute({ component: Component, path }) {
  const rawPath =
    store.getState().core.user.data?.role === Config.ROLE.ADMIN_ROLE
      ? Config.PATHNAME.HOME_ADMIN
      : Config.PATHNAME.HOME_STAFF;
  return (
    <Route
      path={path}
      render={(props) =>
        !UserService.isLogin() ? (
          <LandingLayout>
            <Component {...props} />
          </LandingLayout>
        ) : (
          <Redirect to={{ pathname: rawPath }} />
        )
      }
    />
  );
}

AuthRoute.propTypes = {
  component: propTypes.oneOfType([propTypes.object, propTypes.func]).isRequired,
  path: propTypes.string.isRequired,
};
/**
 *
 */
class Routes extends React.Component {
  backHome = () => {
    const path =
      store.getState().core.user.role ===
      (Config.ROLE.ADMIN_ROLE || Config.ROLE.USER_ROLE)
        ? Config.PATHNAME.HOME_ADMIN
        : Config.PATHNAME.HOME_STAFF;
    window.location.replace(`/#${path}`);
  };

  render() {
    return (
      <Router>
        <Switch>
          {RouteList.authRoutes.map(({ path, component }) => (
            <AuthRoute key={path} component={component} path={path} />
          ))}
          {RouteList.publicRoutes.map(({ path, component }) => (
            <PublicRoute key={path} component={component} path={path} />
          ))}
          {RouteList.privateRoutes.map(({ path, component, children, role }) =>
            children ? (
              children.map((child) => (
                <PrivateRoute
                  key={path + child.path}
                  component={child.component}
                  path={path + child.path}
                  exact={path === Config.PATHNAME.HOME}
                  role={role}
                />
              ))
            ) : (
              <PrivateRoute
                key={path}
                component={component}
                path={path}
                exact={path === Config.PATHNAME.HOME}
                role={role}
              />
            )
          )}
          <Route
            render={() => (
              <Translation>
                {() => (
                  <Result
                    status="404"
                    title="404"
                    subTitle={i18next.t("link does not exist")}
                    extra={
                      <Button type="primary" onClick={this.backHome}>
                        {i18next.t("return")}
                      </Button>
                    }
                  />
                )}
              </Translation>
            )}
          />
        </Switch>
      </Router>
    );
  }
}

export default Routes;
