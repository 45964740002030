import {withTranslation} from "react-i18next";
import AbstractFactory from "./AbstractFactory";

export class ComponentFactory extends AbstractFactory {
    get(Class) {
        return withTranslation("translations", {withRef: true})(this.getClass("Component", Class));
    }
}

const componentFactory = new ComponentFactory();

export default componentFactory;
