import React      from "react";
import Framework  from "../../../../framework/Framework";
import classNames from "classnames";

class Main extends Framework.CoreComponent {

    LAYOUT_CONFIG = window.factory?.LAYOUT_CONFIG ?? {};

    render() {
        const {useSidebar, useNavbar} = this.LAYOUT_CONFIG;

        return (
            <div className={classNames(
                "main",
                useNavbar ? "has-navbar" : "",
                useSidebar ? "has-sidebar" : "",
                this.props.menu.isOpen ? "sidebar-open" : ""
            )}>
                {this.props.children}
            </div>
        );
    }
}

class MainContainer extends Framework.CoreContainer {
    static mapState(state) {
        const {menu} = state.core;
        return {
            menu
        };
    }
}

export default Framework.ContainerFactory.get(MainContainer).withRouter(
    Framework.ComponentFactory.get(Main)
);
