import {connect}    from "react-redux";
import {withRouter} from "react-router-dom";

export default class CoreContainer {
    static className = "CoreContainer";

    component;

    constructor(component) {
        this.component = component;
    }

    static mapState() {
        return {};
    }

    static mapDispatch() {
        return {};
    }

    static mapStateToProps() {
        return (state) => ({
            ...this.mapState(state)
        });
    }

    static mapDispatchToProps() {
        return (dispatch) => ({
            ...this.mapDispatch(dispatch)
        });
    }

    static getConnect(
        component,
        mapState = this.mapStateToProps(),
        mapDispatch = this.mapDispatchToProps()
    ) {
        return connect(mapState, mapDispatch)(component);
    }

    static withRouter(
        component,
        mapState = this.mapStateToProps(),
        mapDispatch = this.mapDispatchToProps()
    ) {
        return withRouter(this.getConnect(
            component,
            mapState,
            mapDispatch
        ));
    }
}
