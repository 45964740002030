import React from "react";
import Framework from "../../../framework/Framework";
import { Button, Table, Tooltip, Select, DatePicker, Space, Form } from "antd";
import autoBind from "../../../utils/autoBind/AutoBind";
import { FileExcelOutlined } from "@ant-design/icons";
import SiteAndProjectService from "../../../service/SiteAndProjectService";
import ReportService from "../../../service/ReportService";
import Notify from "../../../utils/Notify/Notify";
import { ReportManagementTableConfig } from "./ReportManagementTableConfig";
import * as moment from "moment";
import fileDownload from "js-file-download";
import { stubString } from "lodash";

const { Option } = Select;
const { RangePicker } = DatePicker;

class ReportPage extends Framework.CoreComponent {
  static className = "ReportComponent";

  // state
  constructor(props) {
    super(props);
    this.ReportManagementTableConfig = ReportManagementTableConfig(this);
    this.state = {
      search: null,
      isLoading: false,
      typeModal: "",
      dataSelectedFromSiteDropdown: [],
      dataSelectedFromProjectDropdown: [],
      dataForSiteFilter: [],
      dataForProjectFilter: [],
      newDataProj: [],
      listReport: [],
      eventStartRange: [],
      setDatePreviousStart: "",
      setDatePreviousEnd: "",
      dataSelected: null,
    };
    autoBind(this);
  }

  // life cycle
  componentDidMount() {
    this.handleLoadDataSelect(false, false, false, true);
  }
  // handleChange = (value: string) => {
  // console.log(`selected ${value}`);
  // };

  // function
  // load data select
  handleLoadDataSelect(isFilter, isSort, isSearch, isUpdateSelectField = true) {
    const getArrayFilter = (array) => {
      if (array?.length) {
        return array;
      }
      return undefined;
    };

    const params = {
      filter: isFilter
        ? {
            site_in: getArrayFilter(this.state.dataSelectedFromSiteDropdown),
            id_in: getArrayFilter(this.state.dataSelectedFromProjectDropdown),
          }
        : null,
      search: isSearch ? this.state.search : undefined,
    };
    this.setState({ isLoading: true });
    SiteAndProjectService.getDataOnline
      .getAllProjectAndSite(params)
      .then((res) => {
        if (isUpdateSelectField) {
          this.handleLoadFilterOption();
        }
        this.setState({
          listProject: res.data,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  // load data tbale
  handleLoadData(isFilter, isSort, isSearch) {
    const checkEmpty = () =>
      this.state.dataSelectedFromSiteDropdown.length &&
      this.state.dataSelectedFromProjectDropdown.length &&
      this.state.eventStartRange.length;

    const getArrayFilter = (array) => {
      if (array?.length) {
        return array;
      }
      return undefined;
    };

    if (checkEmpty()) {
      const data = {
        site_in: getArrayFilter(this.state.dataSelectedFromSiteDropdown),
        proj_in: getArrayFilter(this.state.dataSelectedFromProjectDropdown),
        eventStart_range: this.state.eventStartRange,
      };
      const params = {
        filter: isFilter ? data : null,
      };
      this.setState({ isLoading: true });
      ReportService.getDataOnline
        .getAllItems(params)
        .then((res) => {
          this.setState({
            listReport: res?.data,
            isLoading: false,
          });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  handleChangeSiteFilter(e) {
    if (e.length === 0) {
      this.setState({
        dataSelectedFromProjectDropdown: [],
      });
      this.handleLoadData(false, false, true);
    }
    this.setState({
      dataSelectedFromSiteDropdown: e,
    });
    // console.log(this.state.dataSelectedFromProjectDropdown);
  }

  handleChangeProjectFilter(e) {
    if (e.length === 0) {
      this.setState(
        {
          dataSelectedFromProjectDropdown: e,
        },
        () => {
          this.handleLoadData(true, false, false);
        }
      );
    }
    this.setState({
      dataSelectedFromProjectDropdown: e,
    });
  }

  // Get list of sites
  handleLoadFilterOption() {
    SiteAndProjectService.getDataOnline.getListSite().then((res) => {
      const siteFilter = [];
      for (let i = 0; i < res.data.length; i++) {
        siteFilter.push({
          label: res.data[i]?.name,
          value: res.data[i]?.id,
        });
      }
      this.setState({
        dataForSiteFilter: siteFilter,
      });
    });
    SiteAndProjectService.getDataOnline.getListProject().then((res) => {
      const projectFilter = [];
      for (let i = 0; i < res.data.length; i++) {
        projectFilter.push({
          label: res.data[i]?.name,
          value: res.data[i]?.id,
          siteid: res.data[i]?.site?.id,
        });
      }
      this.setState({
        dataForProjectFilter: projectFilter,
        newDataProj: projectFilter,
      });
    });
  }

  handleCloseSiteDropdown(isOpen) {
    if (!isOpen) {
      if (
        !this.state.dataSelectedFromSiteDropdown ||
        this.state.dataSelectedFromSiteDropdown.length === 0
      ) {
        this.handleLoadData(false, null, false);
        this.handleLoadDataSelect(false, null, true);
      } else {
        this.handleLoadData(true, null, false);
        this.handleLoadDataSelect(true, null, true, false);
      }
      const newDataForProjectFilterDropdown = [];
      for (let i = 0; i < this.state.dataForProjectFilter.length; i++) {
        if (
          this.state.dataSelectedFromSiteDropdown.includes(
            this.state.dataForProjectFilter[i].siteid
          )
        ) {
          newDataForProjectFilterDropdown.push(
            this.state.dataForProjectFilter[i]
          );
        }
      }
      this.setState({
        newDataProj: newDataForProjectFilterDropdown,
      });
    }
  }

  handleCloseProjectDropdown(isOpen) {
    if (!isOpen) {
      this.handleLoadData(true, null, false);
    }
  }

  // funtion date picker
  handleChangeDatePicker(value) {
    if (value !== null) {
      const start = moment(value[0]._d);
      const end = moment(value[1]._d);
      const diff = end.diff(start, "days");
      const newEnd = moment(start.subtract(1, "days")._d);
      const newStart = moment(newEnd.subtract(diff, "days")._d);
      this.setState(
        {
          setDatePreviousStart: newStart.format("YYYY/MM/DD"),
          setDatePreviousEnd: moment(newEnd._i).format("YYYY/MM/DD"),
          eventStartRange: [
            new Date(value[0]).toISOString(),
            new Date(value[1]).toISOString(),
          ],
        },
        () => {
          this.handleLoadData(true, false, false);
        }
      );
    } else {
      this.setState({
        setDatePreviousStart: "",
        setDatePreviousEnd: "",
      });
    }
  }

  // Export excell
  handelExport() {
    const getArrayFilter = (array) => {
      if (array?.length) {
        return array;
      }
      return undefined;
    };
    const startDownload = (file, fileName) => {
      fileDownload(file.data, file.headers["x-file-name"] || fileName);
      this.setState({ isLoading: false });
    };
    this.setState({ isLoading: true });
    const data = {
      site_in: getArrayFilter(this.state.dataSelectedFromSiteDropdown),
      proj_in: getArrayFilter(this.state.dataSelectedFromProjectDropdown),
      eventStart_range: this.state.eventStartRange,
    };
    const params = {
      filter: data,
    };
    ReportService.getDataOnline
      .exportExcel(params)
      .then((res) => {
        startDownload(res, "report.xlsx");
        Notify.success("Export successfully!");
      })
      .catch(() => {
        this.setState({ isLoading: false });
        Notify.error("Export failed!");
      });
  }

  // render
  template() {
    return (
      <div className="report-page table-container p-4 m-3">
        <div className="d-flex justify-content-between">
          <div className="d-flex mb-4 justify-space-between">
            <div className="d-flex mr-5">
              <div>
                <p className="mb-2">Select Site</p>
                <Select
                  className="report-select"
                  mode="multiple"
                  allowClear
                  style={{ width: 150 }}
                  optionLabelProp="label"
                  placeholder="Select Site"
                  options={this.state.dataForSiteFilter}
                  showArrow
                  showSearch={false}
                  onChange={this.handleChangeSiteFilter}
                  onDropdownVisibleChange={this.handleCloseSiteDropdown}
                />
                {this.state.dataSelectedFromSiteDropdown.length > 0 && (
                  <p
                    className="position-absolute mb-0"
                    style={{
                      marginTop: "-27px",
                      marginLeft: "12px",
                    }}
                  >
                    {`${this.state.dataSelectedFromSiteDropdown.length} site selected`}
                  </p>
                )}
              </div>
              <div className="ml-4">
                <p className="mb-2">Select Project</p>
                <Select
                  className="report-select"
                  mode="multiple"
                  allowClear
                  style={{ width: 150 }}
                  placeholder="Select Project"
                  options={this.state.newDataProj}
                  optionLabelProp="label"
                  showArrow
                  showSearch={false}
                  onChange={this.handleChangeProjectFilter}
                  onDropdownVisibleChange={this.handleCloseProjectDropdown}
                  value={this.state.dataSelectedFromProjectDropdown}
                />
                {this.state.dataSelectedFromProjectDropdown.length > 0 && (
                  <p
                    className="position-absolute mb-0"
                    style={{
                      marginTop: "-27px",
                      marginLeft: "12px",
                    }}
                  >
                    {`${this.state.dataSelectedFromProjectDropdown.length} project selected`}
                  </p>
                )}
              </div>
            </div>

            <div className="d-flex ml-5">
              <div>
                <p className="mb-2">Select report time</p>
                <Space direction="vertical" size={10} className="report-select">
                  <RangePicker onChange={this.handleChangeDatePicker} />
                </Space>
              </div>
              <div className="ml-4">
                <p className="mb-2">Previous period</p>
                <Space direction="vertical" size={10} className="report-select">
                  <RangePicker
                    disabled
                    placeholder={[
                      this.state.setDatePreviousStart === ""
                        ? "Start date"
                        : this.state.setDatePreviousStart,
                      this.state.setDatePreviousEnd === ""
                        ? "End date"
                        : this.state.setDatePreviousEnd,
                    ]}
                  />
                </Space>
              </div>
            </div>
          </div>
          <Button
            className="btn-report-function"
            icon={<FileExcelOutlined />}
            style={{ marginLeft: 100, marginTop: 28 }}
            onClick={this.handelExport}
          >
            Export Excel
          </Button>
        </div>
        <Table
          className="table"
          columns={this.ReportManagementTableConfig}
          dataSource={this.state.listReport}
          bordered
          // rowSelection={{}}
          pagination={false}
          onChange={this.handleChangeTable}
          loading={this.state.isLoading}
          scroll={{ x: "68vh", y: "68vh" }}
          size="small"
        />
      </div>
    );
  }
}

class ReportContainer extends Framework.CoreContainer {
  static className = "ReportComponent";
}

export default Framework.ContainerFactory.get(ReportContainer).withRouter(
  Framework.ComponentFactory.get(ReportPage)
);
