import {notification} from "antd";

class Notify {

    success(message, description) {
        notification.success({
            message,
            description
        });
    }

    error(message, description) {
        notification.error({
            message,
            description
        });
    }

    warn(message, description) {
        notification.warn({
            message,
            description
        });
    }

    info(message, description) {
        notification.info({
            message,
            description
        });
    }
}

export default new Notify();
