import Framework from "../../framework/Framework";

/**
 *
 */
class ApiReport extends Framework.CoreApi {
  static className = "ApiReport";

  path = "/report";

  pathSite = "/site";

  pathProject = "/proj";

  getAllItems(param) {
    const url = this.getBaseUrl(`${this.path}/energy`);
    return this.get(url, param, { getMeta: true });
  }

  exportExcel(param) {
    const url = this.getBaseUrl(`${this.path}/export-excel`);
    return this.download(url, param, { getMeta: true });
  }

  getListSite() {
    const url = this.getBaseUrl(this.pathSite);
    return this.get(url);
  }

  getListProject() {
    const url = this.getBaseUrl(this.pathProject);
    return this.get(url);
  }

  getExcelReportFile(params) {
      const url = this.getBaseUrl(`${this.path}/export-excel`)
      return this.get(url, params, {getMeta: true});
  }
}

export default Framework.ApiFactory.get(ApiReport);
