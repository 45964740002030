import React from "react";
import Framework from "../../../../framework/Framework";
import { Button, Image, Menu, Modal } from "antd";
import classNames from "classnames";
import SidebarItemList from "./SidebarItemList";
import MenuAction from "../../../../store/action/MenuAction";
import UserService from "../../../../service/UserService";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import propTypes from "prop-types";
import Logo from "../../../../assets/LOGO.png";
import ModalChangePassword from "../../ModalCustom/ModalChangePassword";
import { useSelector } from "react-redux";

/**
 *
 * @type {React.NamedExoticComponent<{readonly location?: *, readonly history?: *, readonly menuClose?: *}>}
 */
const RenderMenu = React.memo(({ history, location, menuClose }) => {
  const [visibleChangePassword, setVisibleChangePassword]
    = React.useState(false);
  const [listSiteAllowed, setListSiteAllowed] = React.useState([]);

  // Redux
  const user = useSelector((state) => state.core.user);
  React.useEffect(() => {
    const siteAllowed = SidebarItemList.filter((element) =>
      element.roleRequired.includes(user.data?.role.toLowerCase())
    );
    setListSiteAllowed(siteAllowed);
  }, []);
  React.useEffect(() => {
    // setTimeout(() => {
    // menuClose();
    // }, 50);
    const ele = document.querySelector(".sidebar .ant-menu-item-selected");
    if (ele) {
      ele.scrollIntoView({ block: "center", inline: "nearest" });
    }
  }, [location]);

  const handleLogout = () => {
    Modal.confirm({
      className: "logout-modal",
      title: "Logout",
      content: "Confirm logout",
      cancelText: "Cancel",
      okText: "Confirm",
      icon: <ExclamationCircleOutlined className="color-primary" />,
      onOk: () => {
        UserService.getDataOnline
          .logOut()
          .then(() => {
            UserService.logOut();
          })
          .finally(() => {
            UserService.logOut();
          });
      },
    });
    menuClose();
  };

  const handleOpenModalChangePassword = () => {
    menuClose();
    setVisibleChangePassword(true);
  };

  const handleCloseModalChangePassword = React.useCallback(() => {
    setVisibleChangePassword(false);
  }, []);

  return (
    <>
      <ModalChangePassword
        isOpen={visibleChangePassword}
        onClose={handleCloseModalChangePassword}
      />
      <Menu
        mode="inline"
        theme="dark"
        defaultSelectedKeys={[location.pathname]}
        defaultOpenKeys={["/" + location.pathname.split("/")[1]]}
      >
        <div className="mb-5 d-flex justify-content-center">
          <Image width={200} src={Logo} preview={false} />
        </div>
        {listSiteAllowed.map(({ path, name }, index) => (
          <Menu.Item
            key={index}
            className="sidebar-item"
            onClick={() => history.push(path)}
          >
            {index + 1}. {name}
          </Menu.Item>
        ))}
      </Menu>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className="logout-item hover-pointer"
        onClick={handleOpenModalChangePassword}
      >
        <Button size="small" type="text">
          <span style={{ color: "white", fontSize: "14px" }}>
            {listSiteAllowed.length + 1}. Change password
          </span>
        </Button>
      </div>
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <div
        className="logout-item hover-pointer mt-2"
        onClick={handleLogout}
        role="button"
      >
        <Button
          className="align-items-center"
          icon={<i className="fas fa-arrow-left icon-logout" />}
          size="small"
          type="text"
        >
          <span style={{ color: "white", fontSize: "14px" }}>Logout</span>
        </Button>
      </div>
    </>
  );
});

RenderMenu.propTypes = {
  history: propTypes.object.isRequired,
  location: propTypes.object.isRequired,
  menuClose: propTypes.func.isRequired,
};

/**
 *
 */
class Sidebar extends Framework.CoreComponent {
  static className = "Sidebar";

  constructor(props) {
    super(props);
    this.companyName = "";
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this.handleLoadData();
  }

  handleLoadData() {}

  template() {
    return (
      <>
        {/* Sidebar overlay. Only work with screen < 768px */}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className={classNames(
            "sidebar-overlay",
            this.props.isOpen ? "open" : ""
          )}
          onClick={this.props.actions.menuToggle}
        />
        {/* Sidebar */}
        {this.state.isLoading ? null : (
          <div
            className={classNames("sidebar", this.props.isOpen ? "open" : "")}
          >
            <div className="mt-5 mb-4 logo-menu-text w-80x">
              {this.companyName}
            </div>
            <RenderMenu
              location={this.props.location}
              history={this.props.history}
              menuClose={() => this.props.actions.menuClose()}
            />
          </div>
        )}
      </>
    );
  }
}

class SidebarContainer extends Framework.CoreContainer {
  static mapState(state) {
    const { isOpen } = state.core.menu;
    return {
      isOpen,
    };
  }

  static mapDispatch(dispatch) {
    return {
      actions: {
        menuToggle: () => dispatch(MenuAction.menuToggle()),
        menuClose: () => dispatch(MenuAction.menuClose()),
      },
    };
  }
}

export default Framework.ContainerFactory.get(SidebarContainer).withRouter(
  Framework.ComponentFactory.get(Sidebar)
);
