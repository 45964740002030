/* eslint-disable no-extend-native,func-names */
String.prototype.upperFirstCharacter = function () {
    return (this.charAt(0).toUpperCase() + this.slice(1));
};

String.prototype.getUrlQueryObj = function () {
    return (this
        .slice(1)
        .split("&")
        .map(p => p.split("="))
        .reduce((obj, [key, value]) => ({...obj, [key]: value}), {}));
};

String.prototype.shortName = function (maxLength = 15) {
    const tmp = this;
    if (tmp.length > maxLength) {
        return tmp.substring(0, maxLength) + "...";
    }
    return tmp.toString();
};
