import React from "react";
import Framework from "../../../../framework/Framework";
import { Avatar, Breadcrumb } from "antd";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import SidebarItemList from "../Sidebar/SidebarItemList";
import UserAction from "../../../../store/action/UserAction";
import MenuAction from "../../../../store/action/MenuAction";
import ModalUserProfile from "../../../screens/1-User/ModalUserProfile";
import autoBind from "../../../../utils/autoBind/AutoBind";

/**
 *
 */
class Navbar extends Framework.CoreComponent {
  constructor(props) {
    super(props);
    this.state = {
      typeModal: "",
    };
    autoBind(this);
  }

  // handleLogout() {
  //   Modal.confirm({
  //     title: "Logout",
  //     content: "Logout Confirmation",
  //     cancelText: "Cancel",
  //     okText: "Confirm",
  //     icon: <ExclamationCircleOutlined className="color-danger" />,
  //     onOk: () => {
  //       UserService.logOut();
  //     },
  //   });
  // }

  handleOpenModal(type) {
    this.setState({ typeModal: type });
  }

  handleCloseModal() {
    this.setState({ typeModal: "" });
  }

  /**
   *
   */
  toggleMenu() {
    this.props.actions.menuToggle();
  }

  /**
   *
   * @returns {[]}
   */
  getName() {
    const arrayName = [];
    const arrayPath = this.props.location.pathname.split("/");
    if (arrayPath[1] === "graph") {
      return ["TOPページ "];
    }
    if (arrayPath.length >= 2) {
      const childFist = SidebarItemList.find(
        (item) => item.path === "/" + arrayPath[1]
      );
      if (childFist) {
        arrayName.push(childFist.name);
        const childSecond = childFist.children?.find(
          (item) => item.path === "/" + arrayPath[2]
        );
        if (childSecond) {
          arrayName.push(childSecond.name);
        }
      }
    }
    return arrayName;
  }

  /**
   *
   * @returns {*}
   */
  template() {
    const arrayName = this.getName();
    const username = this.props.user.data?.username ?? "Superadmin";
    return (
      <div className="navbar d-flex row-vertical-center justify-content-between">
        <ModalUserProfile
          type={this.state.typeModal}
          onClose={this.handleCloseModal}
          dataSource={this.props.user.data}
        />
        <div className="d-flex row-vertical-center">
          <MenuOutlined
            className="mx-4"
            onClick={this.toggleMenu}
            style={{ fontSize: 20 }}
          />
          <Breadcrumb style={{ fontSize: 20 }}>
            <Breadcrumb.Item className="nav-title row-vertical-center">
              {arrayName[0]}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{arrayName[1]}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="group-user-info">
          <div className="d-flex align-items-center">
            <Avatar size="medium" icon={<UserOutlined />} />
            <span
              className="ml-2 d-none d-md-flex font-bold"
              style={{ fontSize: 20 }}
            >
              {username.shortName()}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

/**
 *
 */
class NavbarContainer extends Framework.CoreContainer {
  static mapState(state) {
    const { isOpen, isHidden } = state.core.menu;
    const { user } = state.core;
    return {
      isOpen,
      isHidden,
      user,
    };
  }

  static mapDispatch(dispatch) {
    return {
      actions: {
        menuToggle: () => dispatch(MenuAction.menuToggle()),
        userLogout: () => dispatch(UserAction.userLogout()),
      },
    };
  }
}

/**
 *
 */
export default Framework.ContainerFactory.get(NavbarContainer).withRouter(
  Framework.ComponentFactory.get(Navbar)
);
