const REACT_EXCLUDE_METHODS = {
    getChildContext: true,
    render: true,
    template: true,
    componentWillMount: true,
    componentDidMount: true,
    componentWillReceiveProps: true,
    shouldComponentUpdate: true,
    componentWillUpdate: true,
    componentDidUpdate: true,
    componentWillUnmount: true
};

function isExcluded(methodName) {
    return (REACT_EXCLUDE_METHODS[methodName] === true);
}

function isFunction(item) {
    return (typeof item === "function");
}

export default function autoBind(instance, proto) {
    if (proto == null) {
        proto = Object.getPrototypeOf(instance);
    }
    const propertyNames = Object.getOwnPropertyNames(proto);
    // eslint-disable-next-line no-restricted-syntax
    for (const name of propertyNames) {
        const value = proto[name];
        if (isFunction(value) && !isExcluded(name)) {
            instance[name] = proto[name].bind(instance);
        }
    }
}
