import React, {Component} from "react";
import {Provider}         from "react-redux";
import {PersistGate}      from "redux-persist/es/integration/react";
import store, {persistor} from "./store/store";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/js/all.min";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "antd/dist/antd.css";
import "./assets/scss/_app.scss";

import {I18nextProvider} from "react-i18next";
import i18n              from "./i18n";
import Routes            from "./routes";

// Enable config to global variable
import {mapConfigToWindow} from "./config";
import autoBind            from "./utils/autoBind/AutoBind";

// Enable config to global variable
mapConfigToWindow();

/**
 *
 */
class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            companyName: ""
        };
        autoBind(this);
    }

    componentDidMount() {
        this.handleLoadData();
    }

    handleLoadData() {
    }

    render() {
        return (
            <I18nextProvider i18n={i18n}>
                <title>{this.state.companyName ?? "IOT"}</title>
                <Provider store={store}>
                    <PersistGate persistor={persistor}>
                        <Routes/>
                    </PersistGate>
                </Provider>
            </I18nextProvider>
        );
    }
}

export default App;
