import React from "react";
import Framework from "../../../framework/Framework";
import { Button, Input } from "antd";
import UserAction from "../../../store/action/UserAction";
import autoBind from "../../../utils/autoBind/AutoBind";
import UserService from "../../../service/UserService";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import Notify from "../../../utils/Notify/Notify";

/**
 *
 */
export class Login extends Framework.CoreComponent {
  static className = "LoginComponent";

  constructor(props) {
    super(props);

    this._requiredFields = [
      {
        label: "Username",
        key: "username",
      },
      {
        label: "Password",
        key: "password",
      },
    ];

    this.state = {
      username: "",
      password: "",
      isLoading: false,
      companyName: "",
    };
    autoBind(this);
  }

  componentDidMount() {
    this.handleLoadData();
  }

  handleLoadData() {}

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value.replace(/\s/g, ""),
    });
  }

  handleLogin() {
    const { username, password } = this.state;

    if (
      !username
      || !password
      || username.trim().length === 0
      || password.trim().length === 0
    ) {
      Notify.error("Please enter the data in all cells.");
      return;
    }
    if (username.trim().length < 5) {
      Notify.error("Username length must be between 5-30 characters.");
      return;
    }
    if (password.trim().length < 5) {
      Notify.error("Password length must be between 5-30 characters.");
      return;
    }
    this.setState({ isLoading: true });
    UserService.loginBase(username, password)
      .then(async (data) => {
        try {
          await this.props.actions.loginResult(data.data);
          const info = await UserService.getDataOnline.getMe();
          this.props.actions.loginResult(info);
          Notify.success("Login successfully.");
          setTimeout(() => {
            window.location.reload();
          }, 300);
        } catch (e) {
          this.setState({ isLoading: false });
          Notify.error(
            "Failed Login!\n"
              + "Username or Password is incorrect. Please try again."
          );
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  handleForgot() {}

  handleEnterPress(event) {
    if (event.key === "Enter") {
      this.handleLogin();
    }
  }

  template() {
    return (
      <div className="login_page mt-2">
        <div>
          <Input
            name="username"
            maxLength={30}
            prefix={<UserOutlined className="_login-icon" />}
            className="input-form mb-3"
            placeholder="Username"
            value={this.state.username}
            onChange={this.handleChange}
            size="large"
            onPressEnter={this.handleEnterPress}
          />
          <Input.Password
            name="password"
            maxLength={30}
            prefix={<LockOutlined className="_login-icon" />}
            placeholder="Password"
            value={this.state.password}
            onChange={this.handleChange}
            className="input-form"
            onPressEnter={this.handleEnterPress}
            size="large"
          />
        </div>
        <div className="mt-3 row-all-center">
          <Button
            type="primary"
            size="medium"
            className="button-form"
            onClick={this.handleLogin}
            loading={this.state.isLoading}
            // disabled={this.state.isLoading || this.state.username.length === 0 || this.state.password.length === 0}
          >
            Log in
          </Button>
        </div>
      </div>
    );
  }
}

export class LoginContainer extends Framework.CoreContainer {
  static className = "LoginContainer";

  static mapDispatch(dispatch) {
    return {
      actions: {
        loginResult: (user) => {
          dispatch(UserAction.userLoginResult(user));
        },
      },
    };
  }
}

export default Framework.ContainerFactory.get(LoginContainer).withRouter(
  Framework.ComponentFactory.get(Login)
);
