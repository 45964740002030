import {createStore}                  from "redux";
import {persistStore, persistReducer} from "redux-persist";
import storage                        from "redux-persist/lib/storage";

import RootReducer from "./reducer/RootReducer";

import Config from "../config";

const persistConfig = {
    key: Config.STORE_NAME,
    storage: storage
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = createStore(
    persistedReducer
);

export const persistor = persistStore(store);

export default store;
