import i18n             from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import vi from "./languages/vi";
import en from "./languages/en";

i18n
    .use(LanguageDetector)

    .init({
        resources: {
            en: {
                translations: en
            },
            vnm: {
                translations: vi
            },
        },
        fallbackLng: "en",
        ns: ["translations"],
        defaultNS: "translations",

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ","
        },

        react: {
            wait: true,
            useSuspense: true
        }
    });
i18n.changeLanguage("en");
export default i18n;
