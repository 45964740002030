import Framework from "../framework/Framework";
import ApiSiteAndProject from "../data/api/ApiSiteAndProject";

/**
 *
 */
class SiteAndProjectService extends Framework.CoreService {
  static className = "SiteAndProjectService";

  getDataOnline = ApiSiteAndProject;
}

export default Framework.ServiceFactory.get(SiteAndProjectService);
