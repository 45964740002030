import { Badge, Select, Tooltip } from "antd";

const arrayOptionSite = [];
const arrayOptionProj = [];
const arrayOptionInverter = [];
const arrayOptionCode = [];
const arrayOptionDevice = [];
const options = [
  { value: "Complete", label: "Complete" },
  { value: "Processing", label: "Processing" },
  { value: "Ignore", label: "Ignore" },
];
const handleDot = (priority) => {
  if (priority === "Critical"){
    return {
      backgroundColor: "red"
    };
  }
  if (priority === "Yellow"){
    return {backgroundColor: "#ffd65b"};
  }
  return {backgroundColor: "White", border: "1px solid #000000"};
};
const AlertManagementTableConfig = (self) => [
  {
    title: "No.",
    width: 60,
    key: "No",
    align: "center",
    render: (__, record, index) => {
      while (arrayOptionSite.length > 0) {
        arrayOptionSite.pop();
      }
      while (arrayOptionProj.length > 0) {
        arrayOptionProj.pop();
      }
      while (arrayOptionCode.length > 0) {
        arrayOptionCode.pop();
      }
      while (arrayOptionInverter.length > 0) {
        arrayOptionInverter.pop();
      }
      while (arrayOptionDevice.length > 0) {
        arrayOptionDevice.pop();
      }
      self.state.listFilterForColumn.sites?.map((item) =>
        arrayOptionSite.push(item)
      );
      self.state.listFilterForColumn.projs?.map((item) =>
        arrayOptionProj.push(item)
      );
      self.state.listFilterForColumn.inverters?.map((item) =>
        arrayOptionInverter.push(item)
      );
      self.state.listFilterForColumn.codes?.map((item) =>
        arrayOptionCode.push(item)
      );
      self.state.listFilterForColumn.deviceSerialNumbers?.map((item) =>
        arrayOptionDevice.push(item)
      );
      return (
        <div className="d-flex align-items-center justify-content-center">
          <div>
            <Badge
              size="small"
              count={false}
              style={handleDot(record.codeCause.priority)}
            />
          </div>
          <div className="ml-2 mt-1">
            {(self.state.currentPage - 1) * self.state.pageSize + index + 1}
          </div>
        </div>
      );
    },
  },
  {
    title: "Project",
    width: 100,
    dataIndex: "proj_name",
    key: "proj_in",
    align: "center",
    sorter: true,
    filters: arrayOptionProj,
    render: (__, record) => (
      // eslint-disable-next-line jsx-a11y/interactive-supports-focus
      <div
        role="button"
        onClick={() => {
          window.open(
            `https://www.auroravision.net/dashboard/#${record.proj.entityId}`
          );
        }}
        style={{
          cursor: "pointer",
        }}
      >
        <p className="mb-0">{record.proj.name}</p>
        <p className="mb-0">{`(${record.proj.entityId})`}</p>
      </div>
    ),
    filterSearch: true,
  },
  {
    title: "Inverter",
    width: 95,
    dataIndex: "inverter",
    key: "loggerEntityID_in",
    align: "center",
    filters: arrayOptionInverter,
    filterSearch: true,
    render: (__, record) => (
      <>
        <p className="mb-0">{record?.loggerName}</p>
        {record?.loggerMACAddress && (
          <p className="mb-0">{`(${record.loggerMACAddress})`}</p>
        )}
      </>
    ),
  },
  {
    title: "Code",
    width: 60,
    dataIndex: ["codeCause", "code"],
    key: "code_in",
    align: "center",
    filters: arrayOptionCode,
    filterSearch: true,
    render: (text, record) => (
      <Tooltip title={record?.codeCause.cause} placement="top" key={`code_${record?.id}`}>
        <p className="mb-0">{record?.codeCause.code}</p>
      </Tooltip>
    ),
  },
  {
    title: "Device serial number",
    width: 100,
    dataIndex: "deviceSerialNumber",
    key: "deviceSerialNumber_in",
    align: "center",
    filters: arrayOptionDevice,
    filterSearch: true,
  },
  {
    title: "Event start",
    width: 80,
    dataIndex: "eventStart",
    key: "eventStart",
    align: "center",
    sorter: true,
    defaultSortOrder: "descend",
    render: (record) => {
      const event = new Date(record);
      if (record) {
        return (
          <div>
            <p className="mb-0">
              {event.getDate() < 10 ? "0" + event.getDate() : event.getDate()}-
              {event.getMonth() + 1 < 10
                ? "0" + (event.getMonth() + 1)
                : event.getMonth() + 1}
              -{event.getFullYear()}
            </p>
            <p className="mb-0">
              {event.getHours() < 10
                ? "0" + event.getHours()
                : event.getHours()}
              :
              {event.getMinutes() < 10
                ? "0" + event.getMinutes()
                : event.getMinutes()}
              :
              {event.getSeconds() < 10
                ? "0" + event.getSeconds()
                : event.getSeconds()}
            </p>
          </div>
        );
      }
      return "";
    },
  },
  {
    title: "Event end",
    width: 80,
    key: "eventEnd",
    dataIndex: "eventEnd",
    align: "center",
    sorter: true,
    render: (record) => {
      const event = new Date(record);
      if (record) {
        return (
          <div>
            <p className="mb-0">
              {event.getDate() < 10 ? "0" + event.getDate() : event.getDate()}-
              {event.getMonth() + 1 < 10
                ? "0" + (event.getMonth() + 1)
                : event.getMonth() + 1}
              -{event.getFullYear()}
            </p>
            <p className="mb-0">
              {event.getHours() < 10
                ? "0" + event.getHours()
                : event.getHours()}
              :
              {event.getMinutes() < 10
                ? "0" + event.getMinutes()
                : event.getMinutes()}
              :
              {event.getSeconds() < 10
                ? "0" + event.getSeconds()
                : event.getSeconds()}
            </p>
          </div>
        );
      }
      return "";
    },
  },
  // {
  //   title: "Technician",
  //   align: "center",
  //   children: [
  //     {
  //       title: "Name",
  //       width: 90,
  //       dataIndex: ["proj", "techName"],
  //       align: "center",
  //       key: 1,
  //     },
  //     {
  //       title: "Phone",
  //       width: 90,
  //       dataIndex: ["proj", "techPhones"],
  //       align: "center",
  //       key: 2,
  //     },
  //     {
  //       title: "Telegram",
  //       width: 90,
  //       dataIndex: ["proj", "techTelegIds"],
  //       align: "center",
  //       key: 3,
  //     },
  //   ],
  // },
  {
    title: "Action",
    width: 90,
    dataIndex: "action",
    key: "action",
    render: (text, record) => (
      <Select
        options={options}
        key={`action_${record.id}`}
        allowClear
        bordered={false}
        style={{ minWidth: "100%" }}
        defaultValue={record.action}
        onChange={(value) => {
          self.handleChangeProjectActionStatus(record, value);
        }}
      />
    ),
  },
];

export default AlertManagementTableConfig;
