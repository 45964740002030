import React       from "react";
import Sidebar     from "./Sidebar/Sidebar";
import Navbar      from "./Navbar/Navbar";
import Content     from "./Content/Content";
import Main        from "./Main/Main";
import TopProgress from "../TopProgress/TopProgress";
import propTypes   from "prop-types";

/**
 *
 */
export default class DashboardLayout extends React.PureComponent {

    LAYOUT_CONFIG = window.factory?.LAYOUT_CONFIG ?? {};

    render() {
        const {useSidebar, useNavbar, useTopProgress} = this.LAYOUT_CONFIG;
        return (
            <div className="wrapperMain">
                <Main>
                    {useNavbar && <Navbar/>}
                    <Content>
                        {this.props.children}
                    </Content>
                </Main>
                {useSidebar && <Sidebar/>}
                {useTopProgress && <TopProgress/>}
            </div>
        );
    }
}

DashboardLayout.propTypes = {
    children: propTypes.oneOfType([
        propTypes.arrayOf(propTypes.node),
        propTypes.node
    ]).isRequired
};

