import MenuConstant from "../constant/MenuConstant";

export default {
    menuOpen: () => ({
        type: MenuConstant.MENU_OPEN
    }),

    menuClose: () => ({
        type: MenuConstant.MENU_CLOSE
    }),

    menuToggle: () => ({
        type: MenuConstant.MENU_TOGGLE
    })
};
