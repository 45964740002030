// NAME
const DB_NAME = "db_default";
const STORE_NAME = "state";

// NETWORK
const NETWORK_CONFIG = {
  HOST: process.env.REACT_APP_URL,
  API_BASE_URL: process.env.REACT_APP_URL + "/api/v1",
};

// PATHNAME
const PATHNAME = {
  HOME: "/",
  DASHBOARD: "/",
  AUTH: "/auth",
  HOME_STAFF: "/alert",
  HOME_ADMIN: "/",
};

// LAYOUT
const LAYOUT_CONFIG = {
  useSidebar: true,
  useNavbar: true,
  useFooter: true,
  useBottomNavigator: true,
  useTopProgress: true,
};

// EXTENSION
const EXTENSION_CONFIG = {
  usePlugin: false,
  useLayout: false,
  useExtension: false,
};

// DATEFORMAT
const DATE_FORMAT = "DD-MM-YYYY";

// ROLE
const ROLE = {
  ADMIN_ROLE: "SuperAdmin",
  USER_ROLE: "Admin",
  VIEWER_ROLE: "Viewer",
};

/**
 *
 */
export function mapConfigToWindow() {
  window.factory = {
    ...(window.factory || {}),
    EXTENSION_CONFIG,
    LAYOUT_CONFIG,
    PATHNAME,
    NETWORK_CONFIG,
    STORE_NAME,
    DB_NAME,
    DATE_FORMAT,
    ROLE,
  };
}

export default {
  DB_NAME,
  STORE_NAME,
  LAYOUT_CONFIG,
  EXTENSION_CONFIG,
  NETWORK_CONFIG,
  PATHNAME,
  DATE_FORMAT,
  ROLE,
};
