import CoreContainer    from "./container/CoreContainer";
import CoreComponent    from "./component/CoreComponent";
import CoreService      from "./service/CoreService";
import CoreApi          from "./api/CoreApi";
import ComponentFactory from "./factory/ComponentFactory";
import ContainerFactory from "./factory/ContainerFactory";
import ServiceFactory   from "./factory/ServiceFactory";
import ApiFactory       from "./factory/ApiFactory";

export default {
    CoreComponent,
    CoreContainer,
    CoreService,
    CoreApi,
    ComponentFactory,
    ContainerFactory,
    ServiceFactory,
    ApiFactory
};
