import React     from "react";
import propTypes from "prop-types";

export default class LandingLayout extends React.PureComponent {
    render() {
        return (
            <div className="wrapper">
                {this.props.children}
            </div>
        );
    }
}

LandingLayout.propTypes = {
    children: propTypes.oneOfType([
        propTypes.arrayOf(propTypes.node),
        propTypes.node
    ]).isRequired
};
