import React from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import store from "../../../store/store";

const UserManagementTableConfig = (self) => [
  {
    title: "No.",
    width: 60,
    align: "center",
    render: (_, __, index) => (
      <div>
        {(self.state.currentPage - 1) * self.state.pageSize + index + 1}
      </div>
    ),
  },
  {
    title: "Name",
    width: 150,
    dataIndex: "fullName",
    align: "center",
    sorter: (a, b) => a.fullName.localeCompare(b.fullName),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Username",
    width: 150,
    dataIndex: "username",
    align: "center",
    sorter: (a, b) => a.username.localeCompare(b.username),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Role",
    width: 150,
    dataIndex: "role",
    align: "center",
  },
  {
    title: "Action",
    width: 100,
    render: (data) => (
      <div className="row-vertical-center action-icon-container">
        {data.role.toLowerCase() !== "superadmin" ? (
          store.getState().core.user.data.role.toLowerCase() !== "admin" ? (
            <>
              <Button
                icon={<EditOutlined className="action-icon-positive" />}
                type="text"
                className="mr-2"
                onClick={self.handleOpenModal.bind(this, "edit", data)}
              />
              <Button
                icon={<DeleteOutlined className="action-icon-negative" />}
                type="text"
                onClick={self.handleOpenModal.bind(this, "delete", data, "")}
              />
            </>
          ) : data.role.toLowerCase() === "viewer" ? (
            <>
              <Button
                icon={<EditOutlined className="action-icon-positive" />}
                type="text"
                className="mr-2"
                onClick={self.handleOpenModal.bind(this, "edit", data)}
              />
              <Button
                icon={<DeleteOutlined className="action-icon-negative" />}
                type="text"
                onClick={self.handleOpenModal.bind(this, "delete", data, "")}
              />
            </>
          ) : (
            <div />
          )
        ) : (
          <div />
        )}
      </div>
    ),
  },
];

export { UserManagementTableConfig };
