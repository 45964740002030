import loglevel           from "loglevel";
import {combineReducers}  from "redux";
import * as ClassReducers from "../reducer";

/**
 *
 * @returns {Reducer<CombinedState<{}>>}
 */
function coreReducer() {

    const reducers = {};

    Object.keys(ClassReducers).filter(dt => dt !== "RootReducer").forEach(key => {
        const reducerName = ClassReducers[key]?.reducerName;
        const reducer = ClassReducers[key]?.reducer;
        if (reducerName && reducer) {
            if (reducers[reducerName]) {
                loglevel.error(`reducerName must be unique! [${key}]`);
            }
            reducers[reducerName] = reducer;
        } else {
            loglevel.error(`reducerName and reducer must be required! [${key}]`);
        }
    });

    return combineReducers(reducers);
}

/*
 *
 */
function rootReducer() {
    return {
        core: coreReducer()
    };
}

export default combineReducers(rootReducer());
