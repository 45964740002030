import React from "react";

export default class CoreComponent extends React.Component {
    beforeHTML;

    afterHTML;

    template() {
        return null;
    }

    render() {
        return (
            <>
                {this.beforeHTML}
                {this.template()}
                {this.afterHTML}
            </>
        );
    }
}
