import React from "react";
import Framework from "../../../framework/Framework";
import { Table, Tooltip, Select } from "antd";
import autoBind from "../../../utils/autoBind/AutoBind";
import AlertManagementTableConfig from "./AlertManagementTableConfig";
import AlertService from "../../../service/AlertService";
import Timer from "../../component/Timer/Timer";
import Notify from "../../../utils/Notify/Notify";

const { Option } = Select;
class AlertManagementPage extends Framework.CoreComponent {
  static className = "AlertComponent";

  // state
  constructor(props) {
    super(props);
    this.AlertManagementTableConfig = AlertManagementTableConfig(this);
    this.state = {
      search: null,
      isLoading: false,
      typeModal: "",
      loggerIntervalSync: 0,
      selectMode: [
        {
          key: "24H",
          value: "Last 24 hours",
        },
        {
          key: "D7",
          value: "Last 7 days",
        },
        {
          key: "D30",
          value: "Last 30 days",
        },
      ],
      selectedMode: 1,
      listAlert: [],
      filter: {
        eventStart_range: [],
        loggerEntityID_in: [],
        code_in: [],
        deviceSerialNumber_in: [],
        "proj.site_in": [],
        proj_in: [],
      },
      sort: ["-eventStart"],
      currentPage: 1,
      pageSize: 100,
      totalItems: null,
      listFilterForColumn: {},
    };
    autoBind(this);
  }

  // life cycle
  componentDidMount() {
    this.setState(
      {
        filter: {
          eventStart_range: this.handleCalculatePastTime(1),
        },
      },
      () => {
        this.handleLoadData(
          this.state.currentPage,
          this.state.pageSize,
          this.state.sort,
          this.state.filter
        );
        this.handleLoadFilterData(this.state.filter);
      }
    );
  }

  // load data for filter
  handleLoadFilterData(params) {
    const body = {
      filter: params,
    };
    AlertService.getDataOnline.getFilterDistinctData(body).then((res) => {
      this.setState({ listFilterForColumn: res.data });
    });
  }

  // Funtions
  handleLoadData(page, pageSize, sort, filter) {
    const params = {
      pageNumber: page === 0 ? 1 : page,
      pageSize: pageSize,
      sort: sort,
      filter: filter,
    };
    this.setState({ isLoading: true });
    AlertService.getDataOnline
      .getAllItems(params)
      .then((res) => {
        this.setState({
          loggerIntervalSync: res.meta?.loggerIntervalSync,
          listAlert: res?.data,
          isLoading: false,
          totalItems: res.meta.totalItems,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  getArrayFilter(array) {
    if (array?.length) {
      return array;
    }
    return undefined;
  }

  handleChangeTable(pagination, filters, sorter) {
    let sortTemp;
    switch (sorter.order) {
      case "ascend":
        sortTemp = [sorter.field];
        break;
      case "descend":
        sortTemp = [`-${sorter.field}`];
        break;
      default:
        sortTemp = ["-eventStart"];
    }
    this.setState(
      {
        sort: sortTemp,
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
        filter: {
          ...this.state.filter,
          eventStart_range: this.handleCalculatePastTime(
            this.state.selectedMode
          ),
          loggerEntityID_in: filters?.loggerEntityID_in || undefined,
          code_in: filters?.code_in || undefined,
          deviceSerialNumber_in: filters?.deviceSerialNumber_in || undefined,
          "proj.site_in": filters["proj.site_in"] || undefined,
          proj_in: filters?.proj_in || undefined,
        },
      },
      () => {
        this.handleLoadData(
          pagination.current,
          pagination.pageSize,
          this.state.sort,
          this.state.filter
        );
      }
    );
  }

  handleCalculatePastTime(dayToPast) {
    const pastDay = new Date(
      new Date().getTime() - dayToPast * 24 * 60 * 60 * 1000
    ).toISOString();
    const today = new Date().toISOString();
    return [pastDay, today];
  }

  handleChangeSelect(value) {
    let timeSelected = null;
    if (value === "24H") {
      timeSelected = 1;
    } else if (value === "D7") {
      timeSelected = 7;
    } else if (value === "D30") {
      timeSelected = 30;
    } else {
      timeSelected = 1;
    }
    this.setState(
      {
        currentPage: 1,
        filter: {
          ...this.state.filter,
          eventStart_range: this.handleCalculatePastTime(timeSelected),
        },
        selectedMode: timeSelected,
      },
      () => {
        this.handleLoadData(
          this.state.currentPage,
          this.state.pageSize,
          this.state.sort,
          this.state.filter
        );
        this.handleLoadFilterData(this.state.filter);
      }
    );
  }

  handleSyncAfterTimer() {
    this.handleLoadData(
      this.state.currentPage,
      this.state.pageSize,
      this.state.sort,
      this.state.filter
    );
  }

  handleChangeProjectActionStatus(record, value) {
    const body = {
      id: record?.id,
      actionStatus: {
        action: value === undefined ? null : value,
      },
    };
    AlertService.getDataOnline.changeProjectActionType(body).then(() => {
      Notify.success("Action changed successfully!");
    });
  }

  // render
  template() {
    return (
      <div className="alert-page table-container m-3 p-4">
        <div className="d-flex justify-content-between mb-3">
          <Tooltip placement="topRight">
            <Select
              style={{ width: 200 }}
              placeholder="Select Mode"
              // allowClear
              onChange={this.handleChangeSelect}
              defaultValue={this.state.selectMode[0].value}
            >
              {this.state.selectMode.map(({ key, value }) => (
                <Option key={key}>{value}</Option>
              ))}
            </Select>
          </Tooltip>
          <div className="d-flex align-items-center">
            <h4 className="mb-0 mr-1">Interval sync alert by:</h4>
            {this.state.loggerIntervalSync && (
              <Timer
                initialMinute={this.state.loggerIntervalSync}
                timeoutFunction={this.handleSyncAfterTimer}
              />
            )}
          </div>
        </div>
        <Table
          className="table"
          columns={this.AlertManagementTableConfig}
          dataSource={this.state.listAlert}
          pagination={{
            size: "default",
            current: this.state.currentPage,
            total: this.state.totalItems,
            defaultPageSize: 100,
            pageSizeOptions: [10, 20, 30, 50, 100],
            showSizeChanger: true,
          }}
          // rowClassName={(record, index) =>
          //   record.priority === "Critical" ? "red" : "yellow"
          // }
          onChange={this.handleChangeTable}
          loading={this.state.isLoading}
          scroll={{ y: "65vh" }}
          size="small"
        />
      </div>
    );
  }
}

class AlertContainer extends Framework.CoreContainer {
  static className = "AlertComponent";
}

export default Framework.ContainerFactory.get(AlertContainer).withRouter(
  Framework.ComponentFactory.get(AlertManagementPage)
);
