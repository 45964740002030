import Framework from "../../framework/Framework";

/**
 *
 */
class ApiSiteAndProject extends Framework.CoreApi {
  static className = "ApiSiteAndProject";

  pathProject = "/proj";

  pathSync = "/site";

  pathSite = "/site";

  getAllProjectAndSite(param) {
    const url = this.getBaseUrl(this.pathProject);
    return this.get(url, param, { getMeta: true });
  }

  editRecord(body, id) {
    const url = this.getBaseUrl(this.pathProject + `/${id}`);
    return this.put(url, body);
  }

  syncListRecord() {
    const url = this.getBaseUrl(this.pathSync + "/sync");
    return this.post(url);
  }

  getListSite() {
    const url = this.getBaseUrl(this.pathSite);
    return this.get(url);
  }

  getListProject() {
    const url = this.getBaseUrl(this.pathProject);
    return this.get(url);
  }
}

export default Framework.ApiFactory.get(ApiSiteAndProject);
