const listError = {
  USER011101:
    "Failed Login! Username or Password is incorrect. Please try again.",
  //   LENGTH_530: "length must be between 5-30 characters.",
  //   NOT_EMPTY: "cannot be empty",
  //   NOT_FOUND: "not found",
  //   TELEG_TOO_MANY_REQ: "Too many request to Telegram",
  //   TELEG_REQ_ERR: "Request to Telegram error",
  //   UNAUTHORIZED: "Unauthorized account",
  //   SIGNIN_EXISTED: "This username is already in use",
  //   LOGIN_FAIL: "Failed Login! Username or Password is incorrect. Please try again.",
  //   CANT_CREATE_ADMIN: "Not within the scope of Admin rights",
  //   "010009": "Technical name length must be between 5-30 characters.",
  //   "010009": "Phone number and Telegram ID must be number and maximum 5 items separated by \";\".",
  //   "010009": "Telegram ID must be number and maximum 5 items separated by \";\".",
  //   "010009": 'Phone number is wrong format',
  //   "010009": 'Telegram ID is wrong format',
  //   "010009": 'Number is not a positive greater than zero',
  //   "010009": "Please input a valid number.",
  //   Save setting has interrupted
  // :
  // "Save setting has interrupted",
  //     USERNAME_NOT_EMPTY
  // :
  // "Please enter the data in all cells.",
  //     01000
  // 9
  // :
  // "Username must be between 5 and 30 characters."
  // "Please enter the data in all cells."
  // :
  // "Please enter the data in all cells.",
  //     01000
  // 9
  // :
  // "Password"
  // 01000
  // 9
  // :
  // "Please enter the data in all cells.",
  //     FULLNAME_LENGTH
  // :
  // "Full name",
  //     CANT_SET_PW_4_SUPERADMIN
  // :
  // 'Not within the scope of  Admin rights',
  //     NOT_MATCH_PW_CURRENT
  // :
  // 'The password must not coincide with the current password.',
  //     NOT_SAME_PW_CURRENT
  // :
  // 'New password must not coincide with the current password',
  //     CANT_SET_INFO_4_ADMIN
  // :
  // 'Not within the scope of Admin rights',
  //     CANT_SET_PW_4_ADMIN
  // :
  // 'Not within the scope of Admin rights',
  //     CANT_UPDATE_ROLE_4_VIEWER
  // :
  // 'Not within the scope of Admin rights',
  //     CANT_DELETE_ADMIN
  // :
  // 'Not within the scope of Admin rights'
};

const en = {
  errorCode: {
    ...listError,
  },
  common: {
    title_button_delete: "Delete",
    title_button_cancel: "Cancel",
    title_button_confirm_text: "Confirm",
  },
  userManagementPage: {},
  siteProjectManagementPage: {},
  reportManagementPage: {},
  confirm: {
    confirm_delete_content: "Confirm Delete?",
    rePassword: "Confirm Password",
  },
  notify: {
    notify_delete_success: "Delete Successfully!",
  },
  search: "Employee Name",
  status: "Status",
  phone: "Phone number",
  num: "No.",
  name: "Fullname",
  role: "Role",
  act: "Action",
  addUser: "Add User",
  removeUser: "Delete User",
  username: "Username",
  synchronize: "synchronized site",
  company: "Company Name",
  site: "Site",
  no: "No.",
  sitename: "Site name",
  pjname: "Project name",
  teleID: "TelegramID",
  insCapa: "Install Capacity (kWp)",
  pekshour: "Peak sunshine hours",
  norUnPr: "Normal unit price",
  norQu1: "Normal Quantity Const.1(%)",
  norQu2: "Normal Quantity Const.2(%)",
  peUnpr: "Peak unit price",
  peQu1: "Peak Quantity Const.1 (%)",
  peQu2: "Peak Quantity Const.2 (%)",
  evn: "EVN unit price",
  frc: "Factory Revenue Const. (%)",
  erc: "EVN Revenue Const. (%)",
  deleteTitle: "Delete",
  deleteContent: "Confirm Delete ?",
  confirmText: "Confirm",
  cancelText: "Cancel",
  changePassword: "Change Password",
  newPassword: "New Password",
  rePassword: "Confirm Password",
  editText: "Edit",
};

export default en;
