import Framework from "../framework/Framework";
import ApiAlert from "../data/api/ApiAlert";

/**
 *
 */
class AlertService extends Framework.CoreService {
  static className = "AlertService";

  getDataOnline = ApiAlert;
}

export default Framework.ServiceFactory.get(AlertService);
