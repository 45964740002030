import UserConstant from "../constant/UserConstant";

export default {
    userLogin: (user) => ({
        type: UserConstant.USER_LOGIN,
        user
    }),

    userLogout: () => ({
        type: UserConstant.USER_LOGOUT
    }),

    userLoginResult: (user) => ({
        type: UserConstant.USER_LOGIN_RESULT,
        user
    }),

    userLoginError: (error) => ({
        type: UserConstant.USER_LOGIN_ERROR,
        error
    })
};
