import React from "react";
import { Col, Input, Modal, Row, Select } from "antd";
import propTypes from "prop-types";
import UserService from "../../../service/UserService";
import Notify from "../../../utils/Notify/Notify";

/**
 *
 */
function ModalUserProfile({ type, dataSource, onClose, onLoad }) {
  // Ref
  const title = React.useRef({
    add: "Create Account",
    edit: "Edit Account",
    profile: "My Profile",
  }).current;
  const listKeyDetail = [
    {
      label: "Name",
      key: "fullName",
      isRequired: true,
      inputType: "",
      placeHolder: "Name",
    },
    {
      label: "Username",
      key: "username",
      isRequired: true,
      maxLength: 30,
      inputType: "",
      placeHolder: "Username",
    },
    {
      label: "Password",
      key: "password",
      isRequired: true,
      maxLength: 30,
      inputType: "password",
      visibleToggle: "true",
      placeHolder: "Password",
    },
    {
      label: "Re-Password",
      key: "rePassword",
      isRequired: true,
      maxLength: 30,
      inputType: "password",
      visibleToggle: "true",
      placeHolder: "Password",
    },
  ];

  const options = [
    {
      value: "Admin",
      label: "Admin",
    },
    {
      value: "Viewer",
      label: "Viewer",
    },
  ];

  // State
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  // const userId = useSelector((state) => state.core.user.data.id);

  // Life cycle
  // React.useEffect(() => {
  //   if (type === "edit") {
  //     console.log("data", data);
  //     UserService.getDataOnline
  //       .getMe()
  //       .then((res) => {
  //         setData({
  //           ...res.data,
  //           password: "",
  //           rePassword: "",
  //         });
  //       })
  //       .catch(() => {
  //         Notify.error("Cannot get the selected record's information!");
  //       });
  //   }
  // }, [type]);
  React.useEffect(() => {
    if (type === "edit") {
      setData({
        ...dataSource,
        password: "",
        rePassword: "",
      });
    }
  }, [type]);

  // Function
  const handleClose = () => {
    setData({});
    onClose();
  };

  const handleChange = (e) => {
    if (
      e.target.name === "username"
      || e.target.name === "password"
      || e.target.name === "rePassword"
    ) {
      setData({ ...data, [e.target.name]: e.target.value.replace(/\s/g, "") });
      return;
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handeChangeRole = (e) => {
    setData({ ...data, role: e });
  };

  const checkValidData = (fieldData, fieldName, minValue, maxValue) => {
    if (type === "add" && !fieldData) {
      return false;
    }
    if (fieldData) {
      if (
        fieldData.trim().length > maxValue
        || fieldData.trim().length < minValue
      ) {
        Notify.error(
          `${fieldName} length must be between ${minValue}-${maxValue}.`
        );
        return false;
      }
    }
    return true;
  };

  const handleOk = () => {
    const { username, fullName, password, rePassword, role } = data;
    if (type === "add") {
      if (!username || !fullName || !password || !rePassword || !role) {
        Notify.error("Please enter the data in all cells.");
        return;
      }
      if (!checkValidData(username, "Username", 5, 30)) return;
      if (!checkValidData(fullName, "Full name", 5, 255)) return;
      if (!checkValidData(password, "Password", 5, 30)) return;
      if (!checkValidData(rePassword, "Password", 5, 30)) return;
      if (password !== rePassword) {
        Notify.error("Re-password don’t match new password.");
        return;
      }
      const body = {
        username: username ?? undefined,
        fullName: fullName ?? undefined,
        password: password ?? undefined,
        role: role,
      };
      setIsLoading(true);
      UserService.getDataOnline
        .addUser(body)
        .then(() => {
          Notify.success("Create account successfully.");
          handleClose();
          onLoad();
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (type === "edit") {
      if (!checkValidData(fullName, "Full name", 5, 255)) return;
      if (password.length > 0) {
        if (!checkValidData(password, "Password", 5, 30)) return;
      }
      if (rePassword.length > 0) {
        if (!checkValidData(rePassword, "Password", 5, 30)) return;
      }
      if (password !== rePassword) {
        Notify.error("Re-password don’t match new password.");
        return;
      }
      const body = {
        username: type === "edit" ? undefined : username,
        newPassword: password || undefined,
        fullName: fullName,
        role: role,
      };
      setIsLoading(true);
      UserService.getDataOnline
        .changeInfoUser(dataSource.id, body)
        .then(() => {
          Notify.success("Edit account successfully.");
          onLoad();
          handleClose();
        })
        .finally(() => {
          setIsLoading(false);
        });
      // }
    }
  };

  // Render
  return (
    <Modal
      visible={["add", "edit", "profile"].includes(type)}
      onCancel={handleClose}
      title={<span className="pl-3">{title[type]}</span>}
      width={750}
      closable={false}
      cancelText="Cancel"
      okText={type === "add" ? "Create" : "Save"}
      onOk={handleOk}
      confirmLoading={isLoading}
    >
      <Row>
        {listKeyDetail.map(
          (
            { label, key, isRequired, maxLength, placeHolder, inputType },
            index
          ) => (
            <Col key={index} span={12} className="mb-3 px-3">
              <h4 className={`${isRequired && type === "add" && "required"}`}>
                {label}
              </h4>
              {inputType === "password" ? (
                <Input.Password
                  maxLength={maxLength}
                  value={data?.[key]}
                  onChange={handleChange}
                  name={key}
                  disabled={key === "username" && type === "edit"}
                  placeholder={placeHolder}
                />
              ) : (
                <Input
                  maxLength={maxLength}
                  value={data?.[key]}
                  onChange={handleChange}
                  name={key}
                  disabled={key === "username" && type === "edit"}
                  placeholder={placeHolder}
                />
              )}
            </Col>
          )
        )}
      </Row>
      <Row>
        <Col span={12} className="px-3">
          <h4 className={type === "add" && "required"}>Role</h4>
          <Select
            onChange={handeChangeRole}
            name="role"
            value={data?.role}
            placeholder="Role"
            options={options}
            size="middle"
          />
        </Col>
      </Row>
    </Modal>
  );
}

ModalUserProfile.defaultProps = {
  dataSource: {},
  onLoad: propTypes.func,
};

ModalUserProfile.propTypes = {
  type: propTypes.string.isRequired,
  dataSource: propTypes.any,
  onClose: propTypes.func.isRequired,
  onLoad: propTypes.func,
};

export default ModalUserProfile;
